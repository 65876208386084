import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import Layout from '../components/simple-layout.js';
import { StaticImage } from 'gatsby-plugin-image';

import { ChevronRightIcon } from '@heroicons/react/solid';
import {
  DesktopComputerIcon,
  RssIcon,
  PuzzleIcon,
} from '@heroicons/react/outline';

const links = [
  {
    title: 'Blog',
    href: '/blog',
    description: 'Read my latest articles',
    icon: RssIcon,
  },
  {
    title: 'Individual Contributors',
    href: '/ic',
    description: 'Resources for ICs',
    icon: DesktopComputerIcon,
  },
  {
    title: 'Coding Puzzles',
    href: '/coding-puzzles',
    description:
      'Coding exercises used in software engineering interviews',
    icon: PuzzleIcon,
  },
];

const NotFoundPage = () => {
  const { notFoundImage } = useStaticQuery(graphql`
    query NotFoundImage {
      notFoundImage: file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);
  const image = {
    image: notFoundImage,
    imageAlt: 'Image of a laptop showing 404 Not Found.',
  };

  return (
    <Layout
      title="404: Not found"
      description="Page not found."
      image={image}
    >
      {/* TODO: Extract this layout as a single-page layout */}
      <div className="relative px-4 pt-4 pb-8 mt-4 bg-gray-50 sm:px-6 lg:pt-6 lg:pb-6 lg:px-8">
        <div className="relative mx-auto max-w-7xl">
          <div className="bg-white">
            <main className="w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-16">
                <StaticImage
                  className="w-auto h-12 mx-auto"
                  layout="fixed"
                  src="../images/404.jpg"
                  width={200}
                  quality={95}
                  alt="404 Not Found"
                />
              </div>
              <div className="max-w-xl py-16 mx-auto sm:py-24">
                <div className="text-center">
                  <p className="text-sm font-semibold tracking-wide text-indigo-600 uppercase">
                    404 Not Found
                  </p>
                  <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                    This page does not exist.
                  </h1>
                  <p className="mt-2 text-lg text-gray-500">
                    The page you are looking for could not be found.
                  </p>
                </div>
                <div className="mt-12">
                  <h2 className="text-sm font-semibold tracking-wide text-gray-500 uppercase">
                    Popular pages
                  </h2>
                  <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                    {links.map((link, linkIdx) => (
                      <li
                        key={linkIdx}
                        className="relative flex items-start py-6 space-x-4"
                      >
                        <div className="flex-shrink-0">
                          <span className="flex items-center justify-center w-12 h-12 rounded-lg bg-indigo-50">
                            <link.icon
                              className="w-6 h-6 text-indigo-700"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="flex-1 min-w-0">
                          <h3 className="text-base font-medium text-gray-900">
                            <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                              <Link
                                to={link.href}
                                title={link.title}
                                className="focus:outline-none"
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                {link.title}
                              </Link>
                            </span>
                          </h3>
                          <p className="text-base text-gray-500">
                            {link.description}
                          </p>
                        </div>
                        <div className="self-center flex-shrink-0">
                          <ChevronRightIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-8">
                    <Link
                      to="/"
                      className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Or go back home{' '}
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
